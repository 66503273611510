/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$white: #fff;
$black: #0a0a0a;
$default-primary-base-color: #1a1a1a;
$default-primary-dark-color: #0d0d0d;
$default-primary-light-color: #404040;
$default-secondary-base-color: #f8f7f6;
$default-secondary-dark-color: #f4f2f1;
$default-secondary-light-color: #ffffff;
$font-muli: 'Poppins', sans-serif;
$font-standard-size: 62.5%;
$dark: #000;
$grey: #7D8A9B;
$pink: #FF1B6F;
$orange: #F56400;
$orange-dark: #8F3A00;
$purple: #5F54ED;
$purple-dark: #1E149E;
